.home-banner{
    background: url('../../assets/images/slider2.jpg');
}
.home-description{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    background-color: var(--color-blue);
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    color: #fff;
    gap: 3rem;
    overflow: hidden;
}

.description-item{
    display: flex;
    gap: 1rem;
    
}

.description-image{
    width: 60px;
    flex-shrink: 0;
}

.description-image img{
    width: 100%;
}

.description-content h3{
    font-size: 25px;
    margin-bottom: 10px;
}

.description-content p{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.home-description>a>div{
    display: flex;
    padding:1.5rem;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    background-color:#8c0b4a;
    color: #fff;
}

.about-section{
    margin-top: 40px;
    margin-bottom: 40px;
}

.about-section h3{
    text-align: center;
    color: #1C1C1C;
    font-size: 36px;
    margin-bottom: 20px;
}

.about-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.about-image{
    overflow: hidden;
    cursor: pointer;
}


.home_about-content h4{
    line-height: 27px;
    font-size: 20px;
    color: var(--color-blue);
}

.home_about-content>p{
    line-height: 27px;
    font-size: 14px;
    font-weight: 700;
    color: #565656;
    margin: 1rem 0;
    text-align: justify;
}

.home_about-content>a>div{
    padding: 10px;
    border-radius: 5px;
    background-color: var(--color-blue);
    max-width: max-content;
    color: #fff;
}

.product-section{
    background: url('../../assets/images/background.jpg');
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.product-section h3{
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color:#fff;
}

.container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
    margin-top: 30px;
}

.home-footer{
    background-color: var(--color-blue);
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
}

.footer-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    overflow: hidden;
}

.footer-item{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 1.75rem;
    background-color:  #d2116f;
    border-radius: 5px;
    gap: 0.5rem;
   color: #fff;
}


.footer-item a,.footer-item p{
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    display: block;
    text-decoration: none;
    color: #fff;
}

.home-footer h3{
    margin-top: 2rem;
    font-size: 24px;
    color: #fff;
    font-weight: 400;
}

.home_footer-media{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.home-footer img{
    width: 25px;
    margin-top: 5px;
    cursor: pointer;
}

.gallary{
    margin-top: 2.5rem;
}

.gallary h3{
    text-align: center;
    font-size: 36px;
    margin: 1rem 0;
}

.home-banner{
    width: 100%;
    position: relative;
    cursor:grab;
}

.home-banner img{
    width: 100%;
    inset: 0;
    z-index: -1;
}

@media screen and (max-width:767px) {
    .home-description{
        display: block;
    }

    .about-container{
        display: block;
    }

    .container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .footer-container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}