.secondary-image{
    width: 100%;
}

.secondary-image img{
    width: 100%;
}

.secondary-content{
    margin: 1.5rem 0;
}

.secondary-content h1{
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: var(--color-blue);
}

.secondary-content p{
    font-size: 18px;
    text-align: center;
    margin: 10px 0;
}

.secondary__product-container{
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    margin:1.5rem 0;
}

.option-item{
    padding: 0.75rem;
    color: #fff;
    background-color: var(--color-blue);
    border: 1px solid #ccc;
    border-right: 5px solid #ccc;
    cursor: pointer;
}

.secondary-product-card{
    padding: 1rem;
    border: 1px solid black;
}

.secondary-card-heading{
    background-color: var(--color-blue);
    border-radius: 0.5rem;
    color: #fff;
    text-align: left;
    padding: 1rem;
}

.secondary-card-content{
    display: grid;
    grid-template-columns: 1fr 4.5fr;
    gap: 1rem;
    margin-top: 1rem;
}

.secondary-card-content>img{
    width: 100%;
}

.secondary-card-content>p{
    text-align: left;
}