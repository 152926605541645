@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: "PT Sans", sans-serif;
}

a{
  font-weight: bold;
    font-size: 14px;
    line-height: 1.3;
    display: block;
    text-decoration: none;
    color: black;
}