:root{
    --color-green:#77AA43;
    --color-blue:#a30d56;
}

.page-padding{
    padding:0 6rem;
}

.scroll{
    width: 35px;
    height: 35px;
    display: flex;
    visibility: hidden;
    background-color: var(--color-blue);
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: all 0.3s linear; 
}

.scroll:hover{
    background-color: #d2116f;
}

@keyframes lefttoright{
    from{
        transform: translateX(-100%);
    }
    to{
        transform: translateX(0);
    }
}

@keyframes righttoleft{
    from{
        transform: translateX(100%);
    }
    to{
        transform: translateX(0);
    }
}

@keyframes bottomtotop {
    from{
        transform: translateY(100%);
    }
    to{
        transform: translateY(0);
    }
}

@keyframes toptobottom {
    from{
        transform: translateY(-100%);
    }
    to{
        transform: translateY(0);
    }
}

@keyframes visible {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@media screen and (max-width:1023px) {
    .page-padding{
        padding: 0 4rem;
    }
}



@media screen and (max-width:768px){
    .page-padding{
        padding: 0 2rem;
    }
}

@media screen and (max-width:480px) {
    .page-padding{
        padding: 0 10px;
    }
}