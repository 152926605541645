.enquiry-form{
    width: clamp(600px,100%,800px);
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: flex-end;
}

.enquiry-form .input-block{
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.enquiry-form .input-block label{
    min-width: 120px;
    text-align: right;
}

.enquiry-form .input-block>div{
    flex: 1;
}

.enquiry-form .input-block input,.enquiry-form .input-block textarea,.enquiry-form .input-block select{
    width: 100%;
    margin-top: 0;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.75rem;
}

.enquiry-form>.input-block input:focus,.enquiry-form>.input-block textarea:focus,select:focus{
    border: 1px solid rgb(14, 198, 231);
    box-shadow: rgb(14, 198, 231) 0.5px 0.5px 3px;
}

@media screen and (max-width:1023px) {
    .enquiry-form{
        width:clamp(500px,100%,700px);
    }
}

@media screen and (max-width:767px) {
    .enquiry-form{
        width: 100%;
    }

    .enquiry-form .input-block{
        flex-direction: column;
        gap: 0.5rem;
    }

    .enquiry-form .input-block label{
        text-align: left;
    }
}

