.about-image{
    width: 100%;
}

.about-image>img{
    width: 100%;
    max-height: 300px;
}

.about-content>h3{
    font-size:36px;
    font-weight: 700;
    color: var(--color-blue);
    text-align: center;
    margin: 2rem 0;
}

.about-content>p{
    font-size: 17px;
    line-height: 1.3;
    margin: 1rem 0;
    text-align: justify;
    line-height: 27px;
}


@media screen and (max-width:767px) {
    .about-content>p{
        font-weight: 500;
        margin: 0.5rem 0;
    }

    .about-content>h3{
        margin: 1rem 0;
    }

    .about-image>img{
        transform: scale(1.2);
    }
}