.contact-image{
    width: 100%;
}

.contact-image>img{
    width: 100%;
    max-height: 490px;
}

.contact-heading{
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: var(--color-blue);
    margin: 1.5rem 0;
}

.contact-content{
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.icon-background{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color:var(--color-blue);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-address{
    display: flex;
    gap: 1rem;
    margin: 0.75rem;
}

.contact-address p,.contact-address a{
    margin: 0.5rem 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.3;
    display: block;
    text-decoration: none;
    color: black;
}

.contact-form-container{
    flex-basis: 70%;
}

@media screen and (max-width:767px) {
    .contact-content{
        flex-direction: column;
    }
}

@media screen and (max-width:1023px) {
    
}