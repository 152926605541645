
  @keyframes banner {
    0%,20%{
      background-image: url('../../../assets/images/slider1.jpg');
    }
    21%,40%{
      background-image: url('../../../assets/images/slider2.jpg');
    }
    41%,60%{
      background-image: url('../../../assets/images/slider3.jpg');
    }
    61%,80%{
      background-image: url('../../../assets/images/slider4.jpg');
    }
    81%,100%{
      background-image: url('../../../assets/images/slider5.jpg');
    }
  }

  .example{
    position: absolute;
    z-index: 100;
    inset: 0;
    display: grid;
    grid-template-columns: repeat(5,1fr);
  }

  .example > .block {
    background-size:500% 500%;
            animation:banner 25s infinite ease;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .example > .block:nth-of-type(1) {
      background-position: 0 0;
    animation-delay: 0 0;
  }
  
  .example>.block:nth-of-type(2){
    
    background-position:calc(-1*100%) calc(0*100%) ;
    animation-delay:0.1s;
  }

  .example>.block:nth-of-type(3){
    
    background-position: calc(-2*100%) calc(0*100%);
    animation-delay:0.2s;
  }
  .example>.block:nth-of-type(4){
    
    background-position: calc(-3*100%) calc(0*100%);
    animation-delay:0.3s;
  }

  .example>.block:nth-of-type(5){
    
    background-position:calc(-4*100%) calc(0*100%);
    animation-delay:0.4s;
  }

  .example>.block:nth-of-type(6){
    
    background-position: calc(0*100%) calc(-1*100%);
    animation-delay:0.3s;
  }

  .example>.block:nth-of-type(7){
    
    background-position:calc(-1*100%) calc(-1*100%);
    animation-delay:0.4s;
  }

  .example>.block:nth-of-type(8){
    
    background-position:calc(-2*100%) calc(-1*100%);
    animation-delay:0.5s; 
  }

  .example>.block:nth-of-type(9){
    
    background-position:calc(-3*100%) calc(-1*100%);
    animation-delay:0.6s;
  }

  .example>.block:nth-of-type(10){
    
    background-position:calc(-4*100%) calc(-1*100%);
    animation-delay:0.7s;
  }

  .example>.block:nth-of-type(11){
    
    background-position:calc(-0*100%) calc(-2*100%);
    animation-delay:0.6s;
  }

  .example>.block:nth-of-type(12){
    
    background-position:calc(-1*100%) calc(-2*100%);
    animation-delay:0.7s;
  }

  .example>.block:nth-of-type(13){
    
    background-position:calc(-2*100%) calc(-2*100%);
    animation-delay:0.8s;
  }

  .example>.block:nth-of-type(14){
    
    background-position:calc(-3*100%) calc(-2*100%);
    animation-delay:0.9s;
  }

  .example>.block:nth-of-type(15){
    
    background-position:calc(-4*100%) calc(-2*100%);
    animation-delay:1s;
  }

  .example>.block:nth-of-type(16){
    
    background-position:calc(-0*100%) calc(-3*100%);
    animation-delay:0.9s;
  }

  .example>.block:nth-of-type(17){
    
    background-position:calc(-1*100%) calc(-3*100%);
    animation-delay:1s;
  }

  .example>.block:nth-of-type(18){
    
    background-position:calc(-2*100%) calc(-3*100%);
    animation-delay:1.1s;
  }

  .example>.block:nth-of-type(19){
    
    background-position:calc(-3*100%) calc(-3*100%);
    animation-delay:1.2s;
  }

  .example>.block:nth-of-type(20){
    
    background-position:calc(-4*100%) calc(-3*100%);
    animation-delay:1.3s;
  }

  .example>.block:nth-of-type(21){
    
    background-position:calc(-0*100%) calc(-4*100%);
    animation-delay:1.4s;
  }

  .example>.block:nth-of-type(22){
    
    background-position:calc(-1*100%) calc(-4*100%);
    animation-delay:1.5s;
  }

  .example>.block:nth-of-type(23){
    
    background-position:calc(-2*100%) calc(-4*100%);
    animation-delay:1.6s;
  }

  .example>.block:nth-of-type(24){
    
    background-position:calc(-3*100%) calc(-4*100%);
    animation-delay:1.7s;
  }

  .example>.block:nth-of-type(25){
    
    background-position:calc(-4*100%) calc(-4*100%);
    animation-delay: 1.8s;
  }