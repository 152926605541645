.contact-image{
    width: 100%;
}

.contact-image>img{
    width: 100%;
    max-height: 490px;
}

.certificate-container{
    position: fixed;
    z-index: 10;
    inset: 0;
    top: 25px;
}

.certificate-box{
    width: 100%;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.certificate-box>img{
    max-height: 90dvh;
    margin-bottom: 10px;
}

.certificate-box>p{
    color: #fff;
}

.cross{
    width: 30px;
    height: 30px;
    color: #fff;
    z-index: 100;
    position: fixed;
    right: 20px;
    top: 50px;
    cursor: pointer;
}

.cross>div{
    width: 100%;
    height: 4px;
    background-color: #fff;
}

.cross>div:first-child{
    transform:translateY(5px) rotate(45deg);
}

.cross>div:last-child{
    transform: rotate(-45deg);
}

.slick-prev{
    left: 20px !important;
}

.slick-next{
    right: 20px !important;
}

@media screen and (max-width:767px) {
    .certificate-box>img{
        max-height: 90dvh;
        margin-bottom: 10px;
        width: 80%;
    }

    .cross{
        right: 15px;
    }
}