.primary-content>h2{
    margin-block: 2rem;
}

.primary-content>h3{
    margin-bottom: 1.5rem;
}

.primary-content>ul{
    margin-left: 1rem;
    margin-bottom: 2rem;
}

.primary-specifications{
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #686868;
}

.primary-specifications:hover{
    background-color:  gainsboro;
}

.primary-specifications>*{
    border: 1px solid var(--color-blue);
    padding: 1rem;
}

.primary-ingredients{
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    color: #686868;
}

.primary-ingredients:hover{
    background-color: gainsboro;
}

.primary-ingredients>*{
    padding: 1rem;
    border: 1px solid var(--color-blue);
}