.navbar-contact{
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
}
.navbar-contact>p{
    padding:0 0.5rem;
    border-right: 1px solid black;
}
.navbar-container{
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0;
}

.navbar-logo{
    width: 250px;
}

.navbar-logo img{
    width: 100%;
}

.navbar-items{
    display: flex;
    gap: 1.5rem;
    list-style: none;
}

.navbar-items li{
    padding: 0.5rem 0.75rem;
    font-weight: bold;
    cursor: pointer;
}

.active{
    border-top: 2px solid var(--color-blue);
    border-bottom: 2px solid var(--color-blue); 
    color: var(--color-blue);
}

.navbar-items>a{
    text-decoration: none;
}

.subNav{
    list-style: none;
    position: absolute;
    width: 200px;
    top: 100%;
    z-index: 1000;
    background-color: #fff;
    display: none;
    background-color: var(--color-blue);
}

.subNav>a{
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
}

.subNav>a>li:hover{
    background-color: var(--color-blue);
    color: #fff;
}

.subNav>a>li{
    padding: 0.75rem;
    border: 1px solid var(--color-blue);
}

.navbar-items a{
    margin: 0;
}

#products{
    font-size: 14px;
}

#products:hover .subNav{
    display: block;
}

.menubar{
    display: none;
}

@media screen and (max-width:1025px) {
    .navbar-container{
        display: block;
    }

    .navbar-container>ul{
        justify-content: center;
    }
}

@media screen and (max-width:767px) {
    .navbar-container{
        display: flex;
    }

    .navbar-items{
        display: none;
        position:fixed;
        top: 0;
        background-color: #fff;
        z-index: 1000;
        padding: 2rem;
        left: 100%;
        bottom: 0;
        right: 0;
        transition: left 1s ease;
    }

    .navbar-items>a,.navbar-items>li{
        font-size: 20px;
        margin: 2rem 0;
    }

    .subNav{
        position: static;
        width: 100%;
    }

    .menubar{
        display: block;
        padding: 0.75rem;
        color: #fff;
        font-weight: 600;
        margin: 0.5rem 0;
    }

    .menubar>div{
        height: 3px;
        width: 20px;
        background-color: #000;
        margin-top: 3px;
        border-radius: 2px;
    }

    .open{
        display: block;
        left: 0;
    }

    .navbar-logo{
        width: 160px;
    }

    #products{
        font-size: 20px;
    }
}