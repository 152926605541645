.contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.contact-form > div {
  flex: 1;
}

.contact-form .input-block {
  flex: 1;
  margin-bottom: 1rem;
}

.input-block label {
  font-weight: bold;
  font-size: 14px;
}

.input-block label > span {
  color: red;
}

.contact-form .input-block input,
.contact-form .input-block textarea {
  width: 100%;
  padding: 0.75rem;
  margin-top: 5px;
  border: 1px solid #ccc;
}
.input-block textarea {
  height: 120px;
}

.contact-form .input-block p {
  font-size: 12px;
  color: red;
  margin-top: 5px;
}

.form-right {
  display: flex;
  flex-direction: column;
}

.form-right .btn {
  max-width: max-content;
  margin-left: auto;
}

.btn {
  padding: 0.75rem 1.5rem;
  background-color: var(--color-blue);
  color: white;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 15px;
}

@media screen and (max-width:767px) {
  .contact-form{
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
}
